import { Grid } from '@audentio/stuff/src';
import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { Flex } from 'components/Flex';
import {
    GridSection,
    GridSectionLeft,
    GridSectionRight
} from 'components/GridSection';
import { Heading } from 'components/Heading';
import Img from 'gatsby-image';
import React from 'react';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';

export const ClientStatsHighlight = ({
    image,
    children,
    logo,
    title,
    description,
    link,
    linkText,
    mediaProps = {},
    contentProps = {},
    secondaryContent,
    alt,
    ...props
}: any) => {
    return (
        <Section wrap vertical="lg" {...props}>
            <GridSection
                alignItems={{
                    _: 'flex-start',
                    lg: 'center',
                }}
                pt={['40px', 'spacing-xl', '80px']}
            >
                <GridSectionLeft
                    px={{
                        _: 0,
                        lg: 'spacing-xl',
                    }}
                    {...contentProps}
                >
                    <Flex direction="column">
                        {logo && (
                            <Box h="60px" maxH="60px" mb="spacing-lg">
                                <Img
                                    fixed={logo}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    imgStyle={{
                                        width: 'auto',
                                        height: '100%',
                                        maxWidth: '200px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>
                        )}
                        {title && (
                            <Heading kind="h5" mt={0} mb="spacing">
                                {title}
                            </Heading>
                        )}

                        {description && (
                            <Paragraph fontSize="body" color="bodyText">
                                {description}
                            </Paragraph>
                        )}

                        {link && (
                            <Button
                                kind="primary"
                                href={link}
                                mt="spacing-lg"
                                maxWidth="200px"
                            >
                                {linkText}
                            </Button>
                        )}

                        {children && (
                            <Box
                                d={{
                                    _: 'block',
                                    lg: 'none',
                                }}
                                pt={['spacing-lg', null, 'spacing-xl']}
                            >
                                {children}
                            </Box>
                        )}
                        {secondaryContent && (
                            <Box
                                d={{
                                    _: 'block',
                                    lg: 'none',
                                }}
                                pb={['spacing-lg', null, 0]}
                                pt="spacing"
                            >
                                {secondaryContent}
                            </Box>
                        )}
                    </Flex>
                </GridSectionLeft>
                <GridSectionRight
                    hasMedia
                    px={0}
                    maxWidth={{ _: '100%', lg: '66%' }}
                    width="100%"
                    {...mediaProps}
                >
                    <Grid
                        columns={{
                            _: 3,
                        }}
                        w={{
                            _: '100%',
                        }}
                        gridGap={0}
                        gridColGap={0}
                        gridRowGap={4}
                    >
                        <Box
                            gridColumn={{
                                _: '1 / span 3',
                                lg: '1 /span 2',
                            }}
                            mt={{
                                _: 0,
                                md: 'spacing-lg',
                                lg: '0',
                            }}
                            mb="spacing"
                            boxShadow="0 5px 11px 0 rgba(0,0,0,0.15)"
                        >
                            {image && <Img fluid={image} alt={alt} />}
                        </Box>

                        {children && (
                            <Flex
                                d={{
                                    _: 'none',
                                    lg: 'flex',
                                }}
                                // pl="spacing-xl"
                                align="center"
                                h="100%"
                            >
                                {children}
                            </Flex>
                        )}

                        {secondaryContent && (
                            <Flex
                                d={{
                                    _: 'none',
                                    lg: 'flex',
                                }}
                                gridColumn={{
                                    _: '1 / span 3',
                                }}
                                align="center"
                            >
                                {secondaryContent}
                            </Flex>
                        )}
                    </Grid>
                </GridSectionRight>
            </GridSection>
        </Section>
    );
};
