import React from 'react';
import { Box } from '../Box';
import { Button } from '../Button';
import { Flex } from '../Flex';

export const ButtonGroup = ({ buttons, ...props }) => {
    return (
        <Flex>
            {buttons.map(button => (
                <Box
                    key={button.text + Math.random() * 5000}
                    mr="12px"
                    pt="spacing"
                    {...props}
                >
                    <Button
                        external={button.external}
                        fill={button.fill}
                        onClick={button.onClick}
                        href={button.href}
                    >
                        {button.text}
                    </Button>
                </Box>
            ))}
        </Flex>
    );
};
