import styled from '@emotion/styled';
import { Box } from 'components/Box';
import { ContentWidth } from 'components/ContentWidth';
import { DualHeading } from 'components/DualHeading';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { Paragraph } from 'components/Paragraph';
import React from 'react';
import { FlexProps } from '../Flex/types';

const Underline = styled(Box)`
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 4px;
    left: 0;
`;

const ColoredHeadingText = ({ color, children, ...props }) => (
    <Box as="span" position="relative" color={color} {...props}>
        <Underline backgroundColor={color} as="span" />
        {children}
    </Box>
);

const ContentCard = ({ color, subtitle, title, children, ...props }: any) => (
    <Box
        width={{ _: '100%', md: '50%', xl: '33.33%' }}
        mt={{ _: 16, lg: 20 }}
        {...props}
    >
        <Box {...props} mx={{ _: 0, md: 20 }} mr={{ _: 20, md: 0 }}>
            <DualHeading
                title={title}
                subtitle={subtitle}
                subtitleColor="bodyText"
                subtitleMarginBottom={4}
                kind="h3"
                mb={4}
                maxWidth={480}
                fontSize="paragraphTitle"
            />
            <Paragraph lineHeight="taller">{children}</Paragraph>
        </Box>
    </Box>
);

type FeaturesProps = {
    id: string,
    title: string | React.ReactNode,
    features: {
        subtitle: string,
        title: string,
        content: string,
        color?: string,
    }[],
};

export function PrincipleFeatures({
    id,
    title,
    features,
    ...rest
}: FeaturesProps & FlexProps) {
    return (
        <LayoutSection id={id} {...rest}>
            <ContentWidth>
                <Heading center fontSize="sectionHeading" as="h2">
                    {title}
                </Heading>

                <Flex direction="row">
                    <Flex direction="row" flexWrap="wrap">
                        {features.map((feature, key) => (
                            <ContentCard
                                key={key}
                                color={feature.color}
                                title={feature.title}
                                subtitle={feature.subtitle}
                            >
                                {feature.content}
                            </ContentCard>
                        ))}
                    </Flex>
                </Flex>
            </ContentWidth>
        </LayoutSection>
    );
}
