import { Grid } from '@audentio/stuff/src';
import { Layout } from 'components/Layout';
import { PrincipleBenefits } from 'components/Principle/Benefits';
import { PrincipleFeatures } from 'components/Principle/Features';
import { PrinciplesNav } from 'components/PrinciplesNav';
import { graphql } from 'gatsby';
import React from 'react';
import { ButtonGroup } from 'src/components/ButtonGroup';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { ClientStatsHighlight } from 'src/components/Methods/experience/ClientStatsHighlight';
import { StatBox } from 'src/components/Methods/metrics/ClientHighlight';
import { Paragraph } from 'src/components/Paragraph';
import { PrincipleHero } from 'src/components/Principle/Hero';

export default function ExperiencePage({ data }) {
    return (
        <Layout
            pageTitleFull="Experience: An Audentio Community Principle"
            siteDescription="Optimize user flow and UI/UX to align with the unique needs of your community. We can help!"
            contactForm
            subnavTitle="Experience"
            subnav={[
                {
                    label: 'Features',
                    id: 'features',
                },
                {
                    label: 'Benefits',
                    id: 'benefits',
                },
            ]}
        >
            <PrincipleHero
                subtitle="Experience"
                title="Delight your users while reaching your goals"
                image={data.hero.childImageSharp.fluid}
            />

            <PrinciplesNav name="experience" />

            <PrincipleFeatures
                id="features"
                title="create a beautiful, easy-to-use community"
                features={[
                    {
                        subtitle: 'strategy',
                        title: 'content strategy and user flow',
                        content:
                            'Understanding what your users want and how you should get there is important for any product or community.',
                    },
                    {
                        subtitle: 'branding',
                        title: 'brand guidelines for consistency',
                        content:
                            'Branding is more than a logo. Your brand communicates ideals, tone, and more with a unified look throughout your products.',
                    },
                    {
                        subtitle: 'experience',
                        title: 'iconography and illustration',
                        content:
                            'Communicate visually. Much like the signs of old, the right symbol can tell your users exactly what they need.',
                    },
                    {
                        subtitle: 'strategy',
                        title: 'wireframing and prototyping',
                        content:
                            'A great design starts with an idea. A wireframe or prototype takes the idea and creates a foundation and rationale for the overall interactive experience.',
                    },
                    {
                        subtitle: 'branding',
                        title: 'leverage custom elements',
                        content:
                            'Every platform has its own characteristics like themes, add-ons, screen overlays, and emotes. Customize them to communicate your brand and enhance the experience.',
                    },
                    {
                        subtitle: 'experience',
                        title: 'user experience and interface',
                        content:
                            "When most people think of website design, they're thinking of UI design. Create both a beautiful visual design and a meaningful user journey using content strategy, UX foundation, and rationale.",
                    },
                ]}
            />

            {/* <CTA title="Ready to build a community?" /> */}

            <MediaSection
                flipped
                title="Our best XenForo experience free for a limited time"
                video="/videos/methods/resources/engagement.mp4"
                videoPoster="/videos/targeted/ace-for-xenforo/ace-video-poster.jpg"
                videoId="ace-for-xenforo-hero-video-resources"
                mt={{ _: '5rem', lg: '6rem', full: '8rem' }}
            >
                <Paragraph pb="spacing">
                    ACE for XenForo is our answer to what is the most ideal
                    interface and experience for a community. AC.UI, a theme
                    centered around a simple and performant design, and Feeds,
                    an add-on that allows filtering of dedicated feeds.
                </Paragraph>
                <ButtonGroup
                    buttons={[
                        {
                            text: 'Learn More',
                            href:
                                'https://community.audent.io/landing/ace-for-xenforo/',
                        },
                        {
                            text: 'watch video',
                            href: 'https://youtu.be/zU2FxgpVNes',
                            external: true,
                        },
                    ]}
                    pt={6}
                />
            </MediaSection>

            <ClientStatsHighlight
                title="CubeCraft Games community"
                logo={data.cubecraftLogo.childImageSharp.fluid}
                image={data.cubecraftExperience.childImageSharp.fluid}
                description="The client was looking to update their brand and community experience to be more inline with their target audience and with that we did a full re-design and logo refinement."
                link="https://www.audent.io/case-study/cubecraft/full"
                linkText="View Case Study"
                pt={[0, 0, 0]}
                alt="Image showing the cubecraft web application."
                gridSectionProps={{
                    borderTop: 'none',
                }}
                secondaryContent={
                    <Grid
                        gridGap={0}
                        gridColGap={0}
                        columns={{
                            _: 2,
                            lg: 3,
                        }}
                        w="100%"
                    >
                        <StatBox
                            title="20%"
                            subtitle="Increase in"
                            kind="User registrations"
                        />
                        <StatBox
                            title="92%"
                            subtitle="Increase in"
                            kind="Daily average users active"
                        />
                        <StatBox
                            title="229%"
                            subtitle="Increase in"
                            kind="Number of posts made"
                        />
                    </Grid>
                }
            >
                <Grid
                    gridGap={0}
                    gridColGap={0}
                    columns={{
                        _: 2,
                        lg: 1,
                    }}
                    spacing={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                    w="100%"
                >
                    <StatBox
                        title="362%"
                        subtitle="Increase in"
                        kind="Number of threads made"
                    />
                    <StatBox
                        title="143%"
                        subtitle="Increase in"
                        kind="Post reactions"
                    />
                </Grid>
            </ClientStatsHighlight>

            <PrincipleBenefits
                id="benefits"
                title="How experience can benefit your community"
                // ctaHref="/contact?source=experience"
                // ctaLabel="Contact us"
                benefits={[
                    'offer a simple, intuitive interface',
                    'attract members with a unique, branded experience',
                    'better highlight memberships, sponsors, and more',
                ]}
                mt={0}
            />

            <LandingFeatureGrid
                center
                sectionHeaderProps={{
                    direction: 'row',
                }}
                offset={false}
                flexDirection="column"
                title="Related articles"
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                featureGridProps={{
                    mx: 0,
                    px: 0,
                    columns: [1, 2, null, 4],
                    spacing: {
                        _: 'spacing-lg',
                    },
                }}
                showContact={false}
                items={[
                    {
                        image: data.understandingMobile.childImageSharp.fluid,
                        heading:
                            'Understanding Mobile vs. Desktop Users: Why Communities Matter on Mobile',
                        content:
                            'The mobile web has had quite a surge over the past 10 years. Starting with just 4% of visitors on the web being on mobile devices in 2010...',
                        href:
                            'https://adjourn.audent.io/understanding-mobile-vs-desktop-users-why-communities-matter-on-mobile-1eb574424b68',
                    },
                    {
                        image: data.acui.childImageSharp.fluid,
                        heading:
                            'AC.UI, our new forum theme and what we did with it',
                        content:
                            'We’ve strived to be thought leaders in the community space, product designers bringing complex but useful products to the XenForo market, and UI/UX designers for what a forum interface...',
                        href:
                            'https://adjourn.audent.io/ac-ui-our-new-core-forum-theme-for-xenforo-and-why-it-is-a-better-experience-8b4c9996506d',
                    },
                    {
                        image: data.improvedForums.childImageSharp.fluid,
                        heading:
                            'How we improved the forum user experience by learning from social media',
                        content:
                            'Online communities, specifically forums, are struggling to keep up with the modern user experience that large social media platforms...',
                        href:
                            'https://adjourn.audent.io/how-we-can-improve-the-forum-user-experience-41e4e036a27f',
                    },
                    {
                        image: data.throughAges.childImageSharp.fluid,
                        heading:
                            'Through the Ages: Content Discoverability UX on Forums',
                        content:
                            'A brief history of UX improvements focused on modernizing and improving classic forum discoverability techniques that XenForo software and others have been using for decades.',
                        href:
                            'https://adjourn.audent.io/through-the-ages-content-discoverability-ux-on-forums-c50a06a8784a',
                    },
                ]}
            />
        </Layout>
    );
}

export const query = graphql`
    query ExperienceQuery {
        hero: file(relativePath: { eq: "images/hero/experience.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        understandingMobile: file(
            relativePath: { eq: "images/experience/understanding-mobile.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        improvedForums: file(
            relativePath: { eq: "images/experience/improved-forums.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        acui: file(relativePath: { eq: "images/experience/ac-ui.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        throughAges: file(
            relativePath: { eq: "images/experience/through-ages.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cubecraftLogo: file(
            relativePath: { eq: "images/experience/cubecraft.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cubecraftExperience: file(
            relativePath: { eq: "images/experience/experience-cubecraft.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
