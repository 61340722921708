import styled from '@emotion/styled';
import { Anchor } from 'components/Anchor';
import { Box } from 'components/Box';
import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import React, { useEffect, useRef } from 'react';
import { SkipNav, SkipNavLocation } from '../SkipNav';

const items = [
    'Foundation',
    'Experience',
    'Wellness',
    'Monetization',
    'Onboarding',
    'Engagement',
    'Gamification',
    'Optimization',
];

const StyledNavItem = styled(Anchor)`
    flex-basis: 120px;
    border-bottom: 1px solid
        ${props => (props.active ? 'black' : props.theme.colors.border)};
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 200ms ease;

    ${props => props.active && `box-shadow: inset 0 -2px 0 0px black;`}

    & .principle-nav-item {
        ${props => props.active && `color: ${props.theme.colors.boldText};`}
        ${props => props.active && `font-weight: bold;`}
    }

    &:hover {
        border-bottom-color: black;

        .principle-nav-item {
            color: ${props => props.theme.colors.boldText};
        }
    }

    @media (min-width: ${props => props.theme.breakpoint.lg}) {
        flex-basis: 200px;
    }
`;

const NavItem = (props: any) => {
    const ref = useRef<HTMLDivElement>();
    useEffect(() => {
        if (props.active) {
            try {
                // ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            } catch (e) {
                // welp, too bad
            }
        }
    }, []);

    return <StyledNavItem _innerRef={ref} {...props} />;
};

export function PrinciplesNav(props: any) {
    const { name } = props;

    return (
        <ContentWidth id="principles-nav">
            <SkipNav
                href="#afterPrinciples"
                buttonText="Skip Principles List"
            />
            <Flex direction="row" overflow="auto">
                {items.map((item, index) => (
                    <NavItem
                        key={index}
                        py={{ _: 12, lg: 20 }}
                        pt={0}
                        flexShrink={{ _: 0, lg: 1 }}
                        active={name === item.toLowerCase()}
                        color="bodyText"
                        href={`/principles/${item.toLowerCase()}`}
                    >
                        <Box
                            as="span"
                            mb={4}
                            fontSize={{ _: 'smallBody', lg: 'body' }}
                        >
                            {'0' + (index + 1)}
                        </Box>

                        <Box
                            as="span"
                            className="principle-nav-item"
                            fontSize={{ _: 'body', xl: 'paragraphTitle' }}
                        >
                            {item}
                        </Box>
                    </NavItem>
                ))}
            </Flex>
            <SkipNavLocation id="afterPrinciples" />
        </ContentWidth>
    );
}
