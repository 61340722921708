import React from 'react';
import { Flex } from '../Flex';

export function GridSectionLeft({ maxWidth = 320, hasMedia, ...props }: any) {
    return (
        <Flex
            flexShrink={0}
            px={32}
            width={
                hasMedia ? { _: '100%', sm: '70%', md: '60%', lg: '100%' } : {}
            }
            maxWidth={
                typeof maxWidth === 'object'
                    ? maxWidth
                    : { _: '100%', lg: maxWidth }
            }
            mt={{ _: 10, md: 0 }}
            {...props}
        />
    );
}

export function GridSectionRight({ hasMedia, ...props }: any) {
    return (
        <Flex
            direction={{ _: 'column', lg: 'row' }}
            flexGrow={1}
            flexWrap="wrap"
            width={
                hasMedia ? { _: '100%', sm: '70%', md: '60%', lg: 'auto' } : {}
            }
            {...props}
        />
    );
}

export function GridSection({ children, ...props }: any) {
    return (
        <Flex width="100%" direction={{ _: 'column', lg: 'row' }} {...props}>
            {children}
        </Flex>
    );
}
