import React from 'react';
import { Box } from 'src/components/Box';
import { ThemeType } from '../theme';

type ResponsiveBreakProps = {
    breakpoint?: keyof ThemeType['breakpoint'],
};

export function ResponsiveBreak({ breakpoint = 'sm' }: ResponsiveBreakProps) {
    return <Box display={{ _: 'none', [breakpoint]: 'block' }} />;
}
