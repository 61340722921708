import { Button } from 'components/Button';
import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { Hero } from 'components/Hero';
import { ThemeProvider } from 'components/ThemeProvider';
import Img from 'gatsby-image';
import React from 'react';
import { ResponsiveBreak } from '../ResponsiveBreak/ResponsiveBreak';

const HeroTitle = ({ title }: { title: string }): React.ReactNode | string => {
    // no breaks
    if (title.indexOf('%b') === -1) return title;

    // has breaks
    const parts = title.split('%b');

    return parts.map((frag, index) =>
        index === parts.length - 1 ? (
            frag
        ) : (
            <>
                {frag}
                <ResponsiveBreak />
            </>
        )
    );
};

/**
 * Principle page hero
 *
 * supports responsive line breaks in title using "%b"
 * e.g. "Line one,%bLine Two"
 */
export function PrincipleHero({ title, subtitle, image }: any) {
    return (
        <ContentWidth>
            <ThemeProvider defaultMode="dark">
                <Hero
                    mt={10}
                    image={
                        image && (
                            <Img
                                loading="eager"
                                style={{ height: '110%' }}
                                fluid={image}
                            />
                        )
                    }
                    minHeight={{ _: '500px', lg: '500px' }}
                    justifyContent="center"
                >
                    <Flex
                        justifyContent="center"
                        direction="column"
                        p={{ _: 16, lg: 20 }}
                        px={{ _: 10, lg: 20 }}
                    >
                        <Heading as="h2" fontSize="paragraphTitle">
                            {subtitle}
                        </Heading>

                        <Heading as="h1" fontSize="sectionHeading">
                            <HeroTitle title={title} />
                        </Heading>

                        <div>
                            <Button
                                href={`/contact?source=${subtitle || title}`}
                                fill="white"
                            >
                                Contact us
                            </Button>
                        </div>
                    </Flex>
                </Hero>
            </ThemeProvider>
        </ContentWidth>
    );
}
