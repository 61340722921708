import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import {
    GridSection,
    GridSectionLeft,
    GridSectionRight,
} from 'components/GridSection';
import { Heading } from 'components/Heading';
import Img from 'gatsby-image';
import React from 'react';
import { Paragraph } from '../Paragraph';
import { Section } from '../Section';
import { SmallSubtitle } from '../SmallSubtitle';
import { AutoplayWhenVisibleVideo } from '../Video';

interface MediaGridProps {
    image?: any;
    bg?: string;
    video?: string;
    videoPoster?: string;
    videoId?: string;
    children?: any;
    title?: string;
    subtitle?: string;
    flipped?: boolean;
    customMedia?: any;
    middleAlign?: boolean;
    mediaProps?: Object;
    contentProps?: Object;
    sectionProps?: Object;
    mediaSectionContentProps?: Object;
    alt?: string;
    vertical?: string;
}

export function MediaSection({
    image,
    video,
    videoPoster,
    videoId,
    children,
    title,
    subtitle,
    flipped = false,
    customMedia = null,
    middleAlign = false,
    mediaProps = {},
    contentProps = {},
    sectionProps = {},
    vertical = 'lg',
    bg = 'secondaryBackground',
    alt,
    ...props
}: MediaGridProps) {
    const mediaGridProps = {
        image,
        video,
        videoPoster,
        videoId,
        children,
        title,
        subtitle,
        flipped,
        customMedia,
        middleAlign,
        mediaProps,
        contentProps,
        sectionProps,
        alt,
    };
    return (
        <Section wrap vertical={vertical} bg={bg} {...props}>
            <MediaGrid {...mediaGridProps} />
        </Section>
    );
}

export const MediaGrid = ({
    image,
    video,
    videoPoster,
    videoId,
    children,
    title,
    subtitle,
    flipped = false,
    customMedia = null,
    middleAlign = false,
    mediaProps = {},
    contentProps = {},
    sectionProps = {},
    alt,
}: MediaGridProps) => {
    const hasMedia = image || video || customMedia;
    return (
        <GridSection
            alignItems={{
                _: 'flex-start',
                md: middleAlign ? 'flex-start' : 'center',
            }}
            {...(flipped
                ? {
                      direction: {
                          _: 'column-reverse',
                          md: 'row-reverse',
                      },
                  }
                : {
                      direction: { _: 'column-reverse', md: 'row' },
                  })}
            {...sectionProps}
        >
            {hasMedia && (
                <GridSectionLeft
                    hasMedia
                    px={0}
                    maxWidth={{ _: '100%', md: '50%', xl: '55%' }}
                    {...(flipped
                        ? {
                              pl: { _: 0, md: 20, lg: 32 },
                          }
                        : {
                              pr: { _: 0, md: 20, lg: 32 },
                          })}
                    {...mediaProps}
                >
                    <Box width="100%" rounded="radius">
                        {image && (
                            <Img
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                fluid={image}
                                alt={alt}
                            />
                        )}

                        {video && (
                            <AutoplayWhenVisibleVideo
                                video={video}
                                videoId={videoId}
                                videoPoster={videoPoster}
                            />
                        )}

                        {customMedia}
                    </Box>
                </GridSectionLeft>
            )}

            <GridSectionRight {...contentProps}>
                <MediaSectionContent
                    title={title}
                    subtitle={subtitle}
                    middleAlign={middleAlign}
                >
                    {children}
                </MediaSectionContent>
            </GridSectionRight>
        </GridSection>
    );
};

export const MediaSectionContent = ({
    title,
    children,
    middleAlign = false,
    subtitle = null,
    isCentered = false,
    kind = 'h4',
    ...props
}) => {
    return (
        <Flex direction="column" {...props}>
            {subtitle && (
                <SmallSubtitle mb="spacing-sm">{subtitle}</SmallSubtitle>
            )}

            {title && (
                <Heading
                    kind={kind}
                    mt={0}
                    mb="spacing"
                    textAlign={{
                        _: 'left',
                        md: middleAlign ? 'right' : 'left',
                        xl: isCentered ? 'center' : null,
                    }}
                >
                    {title}
                </Heading>
            )}

            {children &&
                (typeof children === 'string' ? (
                    <Paragraph fontSize="body" color="bodyText">
                        {children}
                    </Paragraph>
                ) : (
                    children
                ))}
        </Flex>
    );
};
