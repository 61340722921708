import React from 'react';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { HeroComponent } from '../HeroComponent';

export function Hero({
    children,
    image,
    imageOffset = { bottom: '0rem' },
    ...rest
}: any) {
    return (
        <HeroComponent
            p={0}
            mb={40}
            backgroundColor="primaryBackground"
            alignItems="stretch"
            flexDirection={{ _: 'column', md: 'row' }}
            {...rest}
        >
            <Flex width={{ lg: '600px' }}>{children}</Flex>
            <Flex
                // display={{ _: 'none', lg: 'flex' }}
                pos="relative"
                width={{ _: '100%', lg: '55%' }}
                flexGrow={1}
                zIndex={1}
            >
                <Box
                    position={{ _: 'static', lg: 'absolute' }}
                    left={0}
                    w="100%"
                    h="100%"
                    bg="transparent"
                    {...imageOffset}
                >
                    {image}
                </Box>
            </Flex>
        </HeroComponent>
    );
}
