import React from 'react';
import { BoxProps, Box } from 'components/Box';

export function HeroComponent({ children, ...props }: BoxProps) {
    return (
        <Box
            alignItems="center"
            flexDirection="row"
            display="flex"
            position="relative"
            backgroundColor="secondaryBackground"
            p="40px"
            width="100%"
            mt={10}
            {...props}
        >
            {children}
        </Box>
    );
}
